dmx.Attribute('animate-leave', 'mounted', function (node, attr) {
  const { value, modifiers } = attr;
  const { delay, duration, random } = modifiers;

  // repeaters trigger a remove event on nodes that get removed
  node.addEventListener('remove', event => {
    event.preventDefault();

    const pos = {
      top: node.offsetTop,
      left: node.offsetLeft,
      width: node.offsetWidth,
      height: node.offsetHeight,
    };

    requestAnimationFrame(() => {
      node.style.setProperty('position', 'absolute');
      node.style.setProperty('top', pos.top);
      node.style.setProperty('left', pos.left);
      node.style.setProperty('width', pos.width);
      node.style.setProperty('height', pos.height);
      node.offsetParent.appendChild(node);
  
      dmx.animate(node, value, duration, (random ? Math.random() * 10 : this.data.$index) * delay);
    });
  });
});