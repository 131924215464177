dmx.Component('animate', {

  initialData: {
    animating: false,
    visible: false,
  },

  attributes: {
    showeffect: {
      type: String,
      default: null,
    },

    hideeffect: {
      type: String,
      default: null,
    },

    showduration: {
      type: Number,
      default: null,
    },

    hideduration: {
      type: Number,
      default: null,
    },

    showdelay: {
      type: Number,
      default: 0,
    },

    hidedelay: {
      type: Number,
      default: 0,
    },

    visible: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    animate (effect, duration) {
      if (this.props.visible) {
        dmx.animate(this.$node, effect, duration);
      }
    },
  },

  init (node) {
    if (!this.props.visible) {
      node.style.setProperty('visibility', 'hidden');
    }
  },

  performUpdate (updatedProps) {
    if (updatedProps.has('visible')) {
      if (this.props.visible) {
        if (this.props.showeffect) {
          this.set('animating', true);

          dmx.animate(this.$node, this.props.showeffect, this.props.showduration, this.props.showdelay).then(() => {
            this.set('animating', false);
          }).catch(() => {});
        }

        console.log('remove visibility');
        this.$node.style.removeProperty('visibility');
      } else {
        if (this.props.hideeffect) {
          this.set('animating', true);

          dmx.animate(this.$node, this.props.hideeffect, this.props.hideduration, this.props.hidedelay).then(() => {
            this.set('animating', false);
            console.log('add visibility');
            this.$node.style.setProperty('visibility', 'hidden');
          }).catch(() => {});
        }
      }

      this.set('visible', this.props.visible);
    }
  },

});
